/* THIS FILE IS AUTO-GENERATED - DO NOT MAKE CHANGES TO THIS FILE DIRECTLY */

.icon--apple-pay {
    width: 40px;
    height: 16px;
}

.icon--arrow-expand {
    width: 11.5px;
    height: 11.6px;
}

.icon--camera {
    width: 16px;
    height: 16px;
}

.icon--check {
    width: 13.7px;
    height: 10.4px;
}

.icon--chevron-down {
    width: 17px;
    height: 11px;
}

.icon--chevron-down-big {
    width: 36px;
    height: 36px;
}

.icon--chevron-down-small {
    width: 12px;
    height: 8px;
}

.icon--chevron-slider {
    width: 11px;
    height: 17px;
}

.icon--chevron-up {
    width: 17px;
    height: 11px;
}

.icon--chevron-up-small {
    width: 11.15px;
    height: 7.16px;
}

.icon--circle-check {
    width: 49px;
    height: 48px;
}

.icon--circle-info {
    width: 44px;
    height: 44px;
}

.icon--circle-x {
    width: 67px;
    height: 67px;
}

.icon--clock {
    width: 68px;
    height: 68px;
}

.icon--cog {
    width: 19px;
    height: 19px;
}

.icon--copy-link {
    width: 18px;
    height: 18px;
}

.icon--coupon {
    width: 24px;
    height: 17px;
}

.icon--cta-circle--check {
    width: 44px;
    height: 44px;
}

.icon--cta-circle--search {
    width: 44px;
    height: 44px;
}

.icon--cta-circle--x {
    width: 44px;
    height: 44px;
}

.icon--cta-link--arrow-left {
    width: 30px;
    height: 30px;
}

.icon--email {
    width: 15px;
    height: 12px;
}

.icon--expand {
    width: 24px;
    height: 24px;
}

.icon--filters {
    width: 14px;
    height: 12px;
}

.icon--half_star {
    width: 24px;
    height: 24px;
}

.icon--happy-face {
    width: 24px;
    height: 24px;
}

.icon--heart-giving {
    width: 22px;
    height: 20px;
}

.icon--heart-outline {
    width: 20px;
    height: 18.49px;
}

.icon--heart-solid {
    width: 20px;
    height: 18.49px;
}

.icon--info {
    width: 24px;
    height: 24px;
}

.icon--itunes {
    width: 113.3094px;
    height: 40px;
}

.icon--klarna {
    width: 62.72px;
    height: 14px;
}

.icon--lock-locked {
    width: 20px;
    height: 22px;
}

.icon--lock-unlocked {
    width: 20px;
    height: 24px;
}

.icon--logo-one-percent-black {
    width: 40px;
    height: 48px;
}

.icon--logo-wornwear {
    width: 121.6px;
    height: 160.3px;
}

.icon--map-pin {
    width: 24px;
    height: 24px;
}

.icon--minus {
    width: 20px;
    height: 4px;
}

.icon--navigation-primary--cart-full {
    width: 40px;
    height: 40px;
}

.icon--netflix {
    width: 158px;
    height: 42px;
}

.icon--pause {
    width: 12px;
    height: 20px;
}

.icon--paw--event {
    width: 24.34px;
    height: 24.34px;
}

.icon--paw--petition {
    width: 24.79px;
    height: 32px;
}

.icon--paw--volunteer {
    width: 29.48px;
    height: 27.22px;
}

.icon--payment--adyen-field-error {
    width: 16px;
    height: 16px;
}

.icon--payment--amex {
    width: 34px;
    height: 24px;
}

.icon--payment--amex2 {
    width: 35px;
    height: 25px;
}

.icon--payment--apple {
    width: 13px;
    height: 16px;
}

.icon--payment--apple-large {
    width: 50px;
    height: 20px;
}

.icon--payment--bcmc {
    width: 40px;
    height: 26px;
}

.icon--payment--card-amex {
    width: 125.4px;
    height: 78.4px;
}

.icon--payment--card-apple-pay {
    width: 38px;
    height: 26.82px;
}

.icon--payment--card-discover {
    width: 125.4px;
    height: 78.4px;
}

.icon--payment--card-mastercard {
    width: 125.43px;
    height: 78.39px;
}

.icon--payment--card-paypal {
    width: 125.4px;
    height: 78.4px;
}

.icon--payment--card-venmo {
    width: 125.4px;
    height: 78.4px;
}

.icon--payment--card-visa {
    width: 125.43px;
    height: 78.39px;
}

.icon--payment--diners {
    width: 35px;
    height: 25px;
}

.icon--payment--discover2 {
    width: 35px;
    height: 25px;
}

.icon--payment--gift-card {
    width: 24px;
    height: 17px;
}

.icon--payment--giropay {
    width: 35px;
    height: 25px;
}

.icon--payment--giropay-large {
    width: 36px;
    height: 24px;
}

.icon--payment--ideal {
    width: 35px;
    height: 25px;
}

.icon--payment--ideal-large {
    width: 36px;
    height: 24px;
}

.icon--payment--jcb {
    width: 35px;
    height: 25px;
}

.icon--payment--klarna {
    width: 35px;
    height: 25px;
}

.icon--payment--klarna-large {
    width: 51px;
    height: 24px;
}

.icon--payment--maestro {
    width: 35px;
    height: 25px;
}

.icon--payment--mastercard {
    width: 34px;
    height: 24px;
}

.icon--payment--mastercard-logo2 {
    width: 23px;
    height: 15px;
}

.icon--payment--nocard {
    width: 27px;
    height: 18px;
}

.icon--payment--paypal {
    width: 34px;
    height: 24px;
}

.icon--payment--paypal-large {
    width: 50px;
    height: 24px;
}

.icon--payment--sofort {
    width: 35px;
    height: 25px;
}

.icon--payment--sofort-large {
    width: 36px;
    height: 24px;
}

.icon--payment--venmo {
    width: 34px;
    height: 24px;
}

.icon--payment--venmo-large {
    width: 50px;
    height: 24px;
}

.icon--payment--visa {
    width: 34px;
    height: 24px;
}

.icon--payment--visa2 {
    width: 35px;
    height: 25px;
}

.icon--paypal {
    width: 75px;
    height: 18.2px;
}

.icon--paypal-button {
    width: 162px;
    height: 56px;
}

.icon--paypal-color {
    width: 49px;
    height: 14px;
}

.icon--picto-triman {
    width: 458.24px;
    height: 470.72px;
}

.icon--plus {
    width: 20px;
    height: 20px;
}

.icon--print {
    width: 24px;
    height: 24px;
}

.icon--product--check {
    width: 13.7px;
    height: 10.4px;
}

.icon--rating-fit {
    width: 142px;
    height: 20px;
}

.icon--rating-star-filled {
    width: 13px;
    height: 12px;
}

.icon--rating-star-half {
    width: 13px;
    height: 12px;
}

.icon--rating-star-outlined {
    width: 13px;
    height: 12px;
}

.icon--sad-face {
    width: 24px;
    height: 24px;
}

.icon--save {
    width: 13px;
    height: 14px;
}

.icon--ser--1-percent {
    width: 32px;
    height: 32px;
}

.icon--ser--1-percent-cert {
    width: 48px;
    height: 56px;
}

.icon--ser--fairtrade {
    width: 32px;
    height: 32px;
}

.icon--ser--fairtrade-cert {
    width: 109px;
    height: 160px;
}

.icon--ser--hemp {
    width: 32px;
    height: 32px;
}

.icon--ser--onepercent {
    width: 32px;
    height: 32px;
}

.icon--ser--organic {
    width: 32px;
    height: 32px;
}

.icon--ser--quality {
    width: 32px;
    height: 32px;
}

.icon--ser--recycled {
    width: 32px;
    height: 32px;
}

.icon--ser--reorganic {
    width: 32px;
    height: 32px;
}

.icon--ser--repairable {
    width: 32px;
    height: 32px;
}

.icon--ser--traceable {
    width: 32px;
    height: 32px;
}

.icon--ser--wool {
    width: 32px;
    height: 32px;
}

.icon--ser--wornwear {
    width: 32px;
    height: 32px;
}

.icon--ser--yulex {
    width: 32px;
    height: 32px;
}

.icon--service-black {
    width: 25px;
    height: 24px;
}

.icon--share {
    width: 16px;
    height: 21.8px;
}

.icon--shopping-tool--carat-down {
    width: 60px;
    height: 60px;
}

.icon--social--copy {
    width: 22px;
    height: 22px;
}

.icon--social--email {
    width: 19px;
    height: 13.5px;
}

.icon--social--facebook {
    width: 9px;
    height: 19px;
}

.icon--social--instagram {
    width: 18px;
    height: 17px;
}

.icon--social--line {
    width: 277.2px;
    height: 259.4px;
}

.icon--social--linkedin {
    width: 15.3px;
    height: 11.4px;
}

.icon--social--pinterest {
    width: 24px;
    height: 24px;
}

.icon--social--snapchat {
    width: 500px;
    height: 500px;
}

.icon--social--twitter {
    width: 18px;
    height: 14px;
}

.icon--social--youtube {
    width: 20px;
    height: 16px;
}

.icon--software--apple-podcast {
    width: 23px;
    height: 25px;
}

.icon--software--google-podcast {
    width: 24.8px;
    height: 24.8px;
}

.icon--software--spotify {
    width: 22px;
    height: 22px;
}

.icon--store {
    width: 200px;
    height: 140px;
}

.icon--stories--working-knowledge--black-logo-arrow {
    width: 700px;
    height: 700px;
}

.icon--stories--working-knowledge--black-logo-word {
    width: 700px;
    height: 700px;
}

.icon--subnav-menu {
    width: 20px;
    height: 16px;
}

.icon--thumbs-down {
    width: 20px;
    height: 19.2px;
}

.icon--thumbs-up {
    width: 20px;
    height: 19.2px;
}

.icon--trash {
    width: 20px;
    height: 20px;
}

.icon--truck-black {
    width: 25px;
    height: 24px;
}

.icon--volume-muted {
    width: 17px;
    height: 18px;
}

.icon--volume-unmuted {
    width: 17px;
    height: 18px;
}
